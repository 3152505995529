@import 'styles/includes.scss';
@import 'styles/variables.scss';

.Byline {
    display: flex;
    flex-direction: row;

    &__Info {
        min-height: 64px;
        font-family: $fontBrand;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &--Light {
            color: $colorWhite;
        }

        &--Dark {
            color: $colorBlack800;
        }
    }

    &__Image {
        padding-right: 12px;
    }

    &__Name {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.1px;
        font-weight: bold;
    }

    &__Title {
        display: block;
        font-size: 1.6rem;
        line-height: 1.35;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include media(l) {
            line-height: 1.65;
        }
    }
}
