@import 'styles/includes.scss';

.RichText {
    @extend %rich-text;
    @extend %container;

    &--Object {
        padding-left: 0;
        padding-right: 0;
    }

    & figcaption {
        text-align: left;
    }
}
