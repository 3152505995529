@import 'styles/includes.scss';

.Hero {
    $root: &;
    width: 100%;
    background-color: $colorBlack800;
    font-family: $fontBrand;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__Container {
        width: 100%;
        position: relative;
    }

    &__BackgroundContainer {
        width: 100%;
        overflow: hidden;
        height: 41rem;
        position: relative;

        @include media(m) {
            height: 50vh;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 17.6rem;
            bottom: 0;
            left: 0;
            background: linear-gradient(
                to bottom,
                rgba(24, 24, 24, 0),
                rgba(24, 24, 24, 0.13),
                rgba(24, 24, 24, 0.26),
                rgba(24, 24, 24, 0.58),
                $colorBlack800
            );
        }
    }

    &__BackgroundImage {
        width: 100%;
        height: 100%;
        object-fit: cover;

        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: $transitionDelayFast;

        &--isLoaded {
            opacity: 1!important;
        }
    }

    &__Caption {
        position: absolute;
        bottom: 3rem;
        right: $spacingL;
        font-family: $fontBrand;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.55;
        letter-spacing: normal;
        color: $colorGray200;
        text-align: right;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
        z-index: 1;
        width: 20rem;
        word-break: break-word;

        @include media(ml) {
            width: 100%;
            word-break: normal;
            font-size: 1.2rem;
            line-height: 1.4;
        }
    }

    &__TextContainer {
        position: relative;
        z-index: 3;
        @extend %container;
        display: flex;
        margin-bottom: 50px;

        #{$root}--Video & {
            padding-bottom: 5rem;

            @include media(ml) {
                padding-bottom: 0;
            }
        }
    }

    &__InnerContainer {
        margin-top: -2.9rem;

        @include media(m) {
            margin-top: 0;
        }

        &--Video {
            margin-top: 0rem;
        }
    }

    &__Title {
        font-size: 4rem;
        font-weight: bold;
        line-height: 1.3;
        letter-spacing: 0.05rem;
        margin-bottom: $spacingM;
        overflow: hidden;
        color: $colorWhite;
        transform: translateY(40px);
        opacity: 0;
        transition: opacity 0.3s ease-out, transform 0.3s ease-out;
        transition-delay: $transitionDelayMedium;

        #{$root}--isVisible &{
            opacity: 1;
            transform: translateY(0px);
            overflow: visible;
        }

        @include media(m) {
            font-size: 5.2rem;
            line-height: 1.19;
            letter-spacing: 1.5px;
        }
    }

    &__Preamble {
        color: $colorGray100;
        max-width: 61.3rem;
        line-height: 1.65;
        font-size: 2rem;
        overflow: hidden;
        opacity: 0;
        transform: translateY(40px);
        transition: all 0.3s ease-out;
        transition-delay: $transitionDelayMedium;

        #{$root}--isVisible & {
            opacity: 1;
            transform: translateY(0px);

        }

        @include media(m) {
            line-height: 1.6;
            font-size: 2.2rem;
            letter-spacing: normal;
        }
    }
}
