@import 'styles/includes.scss';
@import 'styles/keyframes.scss';

.GuideList {
    $root: &;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $fontBrand;
    background-color: $colorGray10;
    overflow: hidden;
    padding-top: 3.5rem;

    &__Title {
        font-size: 2.6rem;
        letter-spacing: 0.04rem;
        line-height: 3.43rem;
        font-weight: bold;
        text-align: center;
        width: 100%;
        margin-bottom: 1rem;
        @extend %container;

        @include media(l) {
            font-size: 3.5rem;
            letter-spacing: 0.08rem;
            line-height: 4.6rem;
        }

        &--White {
            color: $colorWhite;
            font-size: 2.1rem;
            letter-spacing: 0.02rem;
            line-height: 2.9rem;

            @include media(l) {
                font-size: 2.4rem;
                letter-spacing: 0.05rem;
                line-height: 3rem;
            }
        }

        &--Black {
            color: $colorBlack800;
            font-size: 2.1rem;
            letter-spacing: 0.02rem;
            line-height: 2.9rem;

            @include media(l) {
                font-size: 2.4rem;
                letter-spacing: 0.05rem;
                line-height: 3rem;
            }
        }

        &--Left {
            text-align: left;
        }

        &--EmptyMyList {
            font-size: 2.1rem;
            line-height: 1.38;
            letter-spacing: 0.02rem;
            margin-bottom: $spacingXl;

            @include media(l) {
                line-height: 1.25;
                letter-spacing: 0.05rem;
                font-size: 2.4rem;
                text-align: center;
            }
        }
    }

    &__FilterContainer {
        margin-top: 20px;
        max-width: 700px;
        text-align: center;
        @extend %container;

        @include media(l) {
            margin-top: 36px;
            max-width: 750px;
        }

        & .Button__Container {
            margin: 4px;
            max-height: 43px;
            box-sizing: border-box;
        }
    }

    & .GuideList__FilterLoadMore {
        height: 3.7rem;
        font-size: 1.4rem !important;
        line-height: 1.4rem;
        width: auto;
        margin-top: 0.4rem;
        margin-left: -1.6rem;
        padding-right: 2.3rem!important;
        padding-left: 3.2rem!important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .Button--Secondary {
            border-width: 1px;

            .Button__Content {
                line-height: 1;
            }
        }

        &::before {
            content: '';
            display: flex;
            width: 1rem;
            height: 0.2rem;
            position: absolute;
            left: 1.2rem;
            top: calc(50% - 0.2rem);
            z-index: 10;
            background: $colorBlack800;
        }

        &::after {
            content: '';
            display: flex;
            width: 0.2rem;
            height: 1rem;
            position: absolute;
            left: 1.6rem;
            top: calc(50% - 0.6rem);
            z-index: 10;
            background: $colorBlack800;
        }
        &:hover {
            &::after,
            &::before {
                background: $colorWhite;
            }
        }

        &--Allfilters {
            &::after {
                display: none;
            }
        }

        button.GuideList__FilterLoadMore {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 3.5rem;
        }
    }

    &__FilterLoadMoreContainer {
        visibility: visible;
        display: inline;
        transition: 200ms ease-in-out;
        margin-top: 0.4rem;
    }

    &__CardsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 159px;
        background-color: $colorBlack800;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 3.8rem;

        @include media(l) {
            padding-bottom: 8rem;
        }

        & > #{$root}__Cards {
            min-width: 0;
            margin-right: auto;
            margin-left: auto;
            width: 100%;

            transform: translateY(100px);
            opacity: 0;
            transition: transform 0.5s ease-out, opacity 0.5s ease-out;
            transition-delay: $transitionDelayMedium;

            #{$root}--isVisible & {
                opacity: 1;
                transform: translateY(0px);
            }
        }

        &--Locals,
        &--MyList {
            padding-top: 0;
            background-color: $colorWhiteBackground;

            & .GuidList__Cards {
                padding-top: 0;
            }

            & .CardGuide {
                heigth: 100%;
            }
        }

        /* &--MyList {
            background-color: $colorBlack800;

            & .GuidList__Cards {
                color: $colorWhite;
            }
        }*/
    }

    &__CardsSlider {
        margin-top: 179px;
        background-color: $colorBlack800;
        width: 100%;
        padding-bottom: 3.8rem;

        @include media(l) {
            padding-bottom: 8rem;

            [aria-disabled='true'] {
                background-color: $colorGray200;
            }
        }
    }

    &__CardsSliderWrapper {
        margin-top: -179px;
        @extend %container;
    }

    &__Button {
        background-color: $colorBlack800;
        border-color: $colorGray10;
        color: $colorGray10;
        margin-top: 32px;

        &:hover {
            button {
                background-color: $colorGray10;
                border-color: $colorBlack800;
                color: $colorBlack800;
            }
        }
    }

    &__Cards {
        margin-top: -139px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        flex-basis: 100%;
        min-width: 100%;
        @extend %container;

        @include media(m) {
            margin-right: -16px;
            justify-content: flex-start;
            min-width: calc(100% + 2.4rem);
        }
    }

    &__Card {
        $card: &;
        margin-bottom: $spacingXl;
        flex-basis: 100%;
        max-width: 100%;

        @include media(m) {
            flex-basis: calc(50% - 2.4rem);
            margin-right: 2.4rem;
            max-width: calc(50% - 2.4rem);
            flex-grow: 0;
        }

        @include media(ml) {
            flex-basis: calc(25% - 2.4rem);
            max-width: calc(25% - 2.4rem);
            flex-grow: 0;
        }
    }

    &--MyList {
        padding-top: 0;

        #{$root} {
            &__CardsContainer {
                margin-top: 0;

                @extend %container;
                margin: 0 auto;
            }

            &__Cards {
                margin-top: 3.5rem;
                justify-content: flex-start;
                padding: 0;

                @include media(ml) {
                    margin-right: -2.4rem;
                    width: calc(100% + 2.4rem);
                    padding: 0;
                }

                @include media(l) {
                    padding: 0;
                }

                @include media(xl) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &__ButtonWrapper {
        &--Open {
            animation-name: fadeIn;
            animation-duration: 2s;
        }

        &--Opening {
            opacity: 0;
        }
    }

    &__RelatedInMyList {
        margin-top: 179px;
    }

    &__animation-enter {
        opacity: 0.01;
    }

    &__animation-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-in;
    }

    &__animation-leave {
        opacity: 1;
    }

    &__animation-exit {
        opacity: 1;
    }

    &__animation-exit-active {
        opacity: 0.01;
        transition: opacity 400ms ease-in;
    }

    &__NoResults,
    &__Error {
        margin-top: $spacingL;
        font-size: 1.6rem;
        color: $colorWhite;

        @include media(ml) {
            margin-top: $spacingXl;
            font-size: 2rem;
        }
    }

    &__LoaderContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 0;
    }

    &__Loader {
        display: block;
        position: relative;
        width: 3rem;
        height: 3.66rem;
        z-index: 2;

        &::before {
            position: absolute;
            display: block;
            content: '';
            border-left: 1.5rem solid $colorBlue50;
            border-right: 1.5rem solid transparent;
            border-bottom: 1.83rem solid $colorBlue50;
            border-top: 1.83rem solid transparent;
            animation: loader 3s infinite;
            animation-delay: 0.75s;
        }
        &::after {
            position: absolute;
            display: block;
            content: '';
            border-left: 1.5rem solid transparent;
            border-right: 1.5rem solid $colorGreen50;
            border-bottom: 1.83rem solid transparent;
            border-top: 1.83rem solid $colorGreen50;
            animation: loader 3s infinite;
            animation-delay: 1s;
        }

        &:first-of-type {
            &::before {
                border-left: 1.5rem solid $colorOrange50;
                border-bottom: 1.5rem solid $colorOrange50;
                animation-delay: 0s;
            }
            &::after {
                border-right: 1.5rem solid $colorPurple50;
                border-top: 1.5rem solid $colorPurple50;
                animation-delay: 0.25s;
            }
        }
    }
}
