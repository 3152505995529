@import 'styles/includes.scss';

.CardEvent {
    $root: &;
    display: flex;
    flex-direction: column;
    text-decoration: none;

    &--MyList {
        @include media(ml) {
            padding: 2.4rem !important;
        }

        &#{$root}--Line:first-of-type {
            border-top: none !important;
        }
    }

    &:hover {
        .CardEventPicture__DateBackground--Medium,
        .CardEventPicture__Picture--Small img {
            transform: scale(1.1) rotate(-40deg);
        }
        .CardEventPicture__Picture--Medium img,
        .CardEventPicture__Picture--Small img,
        .CardEventPicture__Picture--Large img {
            transform: scale(1.1);
        }
    }

    @include media(l) {
        min-width: calc((#{map-get($maxwidths, l)} - 72px) / 4);
    }

    @include media(xl) {
        min-width: calc((#{map-get($maxwidths, xl)} - 72px) / 4);
    }

    &__IconWrapper {
        margin-right: $spacingS;
        display: flex;
        align-items: center;
        margin-top: -0.1rem;

        @include media(m) {
            margin-top: -0.7rem;
        }
    }

    &__Content {
        color: $colorWhite;
        font-family: $fontBrand;
    }

    &__RemoveButton {
        display: none;
    }

    &__Title {
        display: block;
        font-size: 1.7rem;
        font-weight: bold;
        margin: 20px 0 10px;
        line-height: 1.41;
        color: $colorWhite;
        letter-spacing: 0.2px;

        @include media(m) {
            font-size: 1.9rem;
            line-height: 1.68;
            letter-spacing: 0.4px;
            max-width: 100%;
        }
        #{$root}--White & {
            color: black;
        }
    }

    &__Label {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.26rem;
        color: $colorGray700;
        margin-bottom: 1.1rem;

        @include media(ml) {
            font-size: 1.4rem;
            color: $colorBlack800;
            margin-bottom: 1.9rem;
        }
    }

    &__Category {
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 2.6px;
        color: $colorGray200;

        @include media(m) {
            font-size: 1.4rem;
        }

        #{$root}--White & {
            color: $colorGray700;
        }
    }

    &__MetaRow {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        font-size: 1.2rem;
        line-height: 1.6;
        color: $colorGray200;
        align-items: center;

        @include media(m) {
            font-size: 1.6rem;
            line-height: 1.65;
        }

        .Icon {
            margin-right: 10px;
        }

        #{$root}--White & {
            color: $colorGray700;
        }
    }

    &--Line {
        border-top: 1px solid $colorGray200;
    }

    &--PaddingVertical {
        padding-top: $spacingL;
        padding-bottom: $spacingL;
    }

    &--Active {
        @include media(ml) {
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            border-bottom: 4px solid $colorPink;
            box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.15);
            background-color: $colorGray40;
        }
    }

    &--Horizontal {
        flex-direction: row;
        max-width: none;

        #{$root}__Content {
            color: $colorBlack;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;

            @include media(m) {
                padding-left: $spacingXl;
            }
        }

        #{$root}__Title {
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.43;
            letter-spacing: 0.2px;
            margin: 0;
            color: $colorBlack;

            @include media(m) {
                font-size: 1.9rem;
                line-height: 1.68;
                letter-spacing: 0.4px;
                margin-bottom: 6px;
                max-width: calc(100% - 69px);
            }
        }

        #{$root}__Category {
            font-size: 1.2rem;
            color: $colorGray700;
            margin-bottom: 10px;
            letter-spacing: 2.6px;

            @include media(m) {
                margin-top: 5px;
                font-size: 1.4rem;
                margin-bottom: 16px;
            }
        }

        #{$root}__RemoveButton {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            font-family: $fontBrand;
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: $colorGray500;
            padding: 0.2rem;
            text-decoration: underline;
        }

        #{$root}__IconSize {
            width: 1rem;
            margin-right: 0.6rem;
        }

        #{$root}__MetaRow {
            font-size: 1.2rem;
            margin-top: 0;
            margin-bottom: 5px;
            line-height: 1.6;
            color: $colorGray700;
        }

        #{$root}__IconWrapper {
            margin-right: $spacingS;
            margin-top: 0;
        }

        #{$root}__Description {
            color: $colorBlack750;
            font-size: 1.6rem;
            line-height: 1.65;
            margin-bottom: 10px;

            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            @include media(m) {
                display: block;
            }
        }

        .CardEventPicture__PictureLabel {
            display: none;
        }

        .CardEventPicture {
            width: 9rem;
            @include media(m) {
                width: 21.6rem;
            }
        }
    }

    .CardEventPicture {
        position: relative;
        max-width: 100%;
        flex: 0 0 auto;

        &--Small {
            width: 9rem;

            @include media(l) {
                width: 21.6rem;
            }
        }

        &--Medium {
            width: 100%;
            @include media(l) {
                width: 100%;
            }
        }

        &--Large {
            width: 60.1rem;
        }

        &__Picture {
            height: 0;
            position: relative;
            overflow: hidden;

            &--Small {
                padding-top: calc(90 / 90 * 100%);

                @include media(l) {
                    padding-top: calc(216 / 216 * 100%);
                }
            }

            &--Medium {
                padding-top: calc(243 / 253 * 100%);

                @include media(l) {
                    padding-top: calc(312 / 297 * 100%);
                }
            }

            &--Large {
                padding-top: calc(397 / 601 * 100%);
            }
        }

        img {
            position: absolute;
            top: 0;
            transition: transform 0.5s;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &__PictureLabel {
            line-height: 1.3;
            background-color: $colorBlack800;
            color: $colorWhite;
            font-family: $fontBrand;
            padding: 7px 24px;
            font-size: 1.4rem;
            border-radius: 20px;
            display: inline-block;
            position: absolute;
            top: 12px;
            right: 12px;
        }

        &__DateContainer {
            color: $colorBlack800;
            font-family: $fontBrand;
            z-index: 1000;
            position: absolute;
            left: $spacingL;
            bottom: 21px;
            line-height: 1.1;

            &--SmallOnDesktop {
                left: 5px;
                bottom: 5px;

                @include media(l) {
                    left: 10px;
                    bottom: 10px;
                }
            }

            &--Day {
                display: block;
                font-weight: bold;
                font-size: 2.6rem;
                line-height: 1.32;
                letter-spacing: 3px;
            }

            &--Small {
                font-size: 1.7rem;
                line-height: 1.2;
                letter-spacing: 0.2px;

                @include media(l) {
                    font-size: 1.9rem;
                    line-height: 1.3;
                    letter-spacing: 0.4px;
                }
            }

            &--Medium {
                font-size: 2.6rem;
                line-height: 1.32;
                letter-spacing: 3px;
            }

            &--Large {
                font-size: 2.6rem;
                line-height: 1.32;
                letter-spacing: 3px;
            }

            &--Month {
                display: block;
                text-transform: uppercase;
                font-size: 1.4rem;
                letter-spacing: 2.6px;
                line-height: 1;
            }

            &--Month.CardEventPicture__DateContainer--Small {
                font-size: 1.2rem;
                letter-spacing: 2.6px;
                line-height: 1;
            }
        }

        &__DateBackground {
            z-index: 5;
            position: absolute;
            background-color: $colorBlue50;
            transition: transform 0.5s;

            &--Blue50 {
                background-color: $colorBlue50;
            }

            &--Green50 {
                background-color: $colorGreen50;
            }

            &--Purple50 {
                background-color: $colorPurple50;
            }

            &--Orange50 {
                background-color: $colorOrange50;
            }

            &--Small {
                width: 115px;
                height: 166px;
                bottom: -84px;
                left: -70px;
                transform: rotate(-40deg);

                @include media(l) {
                    bottom: -64px;
                    left: -62px;
                    transform: rotate(-40deg);
                }
            }

            &--Medium {
                width: 133px;
                height: 211px;
                bottom: -74px;
                left: -50px;
                transform: rotate(-40deg);
            }

            &--Large {
                width: 240px;
                height: 170px;
                bottom: -100px;
                left: -106px;
                transform: rotate(45deg);

                @include media(xs) {
                    bottom: -80px;
                    left: -106px;
                }
            }
        }
    }
}
