@import 'styles/includes.scss';

.Header {
    background-color: $colorBlack800;
    position: relative;
    z-index: 10001;

    &__Inner {
        @extend %container;
        color: $colorWhite;
        height: 88px;
        font-family: $fontBrand;
        font-weight: normal;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include media(ls) {
            height: 88px;
        }
    }

    &__ExpandedMenu {
        @extend %container;
        position: fixed;
        overflow-y: scroll;
        width: 100%;
        height: 100vh;
        z-index: 100001;
        top: 0;
        left: 0;
        right: 0;
        background: url('#{$assetPath}img/pattern-mobile.svg') no-repeat right
                bottom,
            $colorBlack800;
        transform: translateX(100%);
        transition: transform 0.4s ease-out;

        @include media(xs) {
            height: 100vh;
        }

        @include media(xl) {
            max-width: 100vw;
            overflow-y: auto;
        }

        &--Show {
            transform: translateX(0);
        }

        .Header__Inner {
            height: auto;
        }
    }

    @include media(ls) {
        height: 88px;
    }

    form {
        display: none;
    }

    a {
        color: $colorWhite;
        text-decoration: none;
    }

    &__Logo {
        width: 15.1rem;
        height: auto;

        @include media(ls) {
            width: 17.6rem;
        }
    }

    &__Brand {
        width: 100%;
        line-height: 0;
    }

    &__Content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__MainNav--Desktop {
        display: none;
    }

    &__MainNav {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 13rem;

        @include media(xl) {
            width: 1440px;
            margin: 0 auto;
        }

        li {
            transform: translateX(10%);
            transition: all 0.4s ease-in-out;
            opacity: 0;
        }

        &--Expanded {
            li {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @for $i from 0 through 10 {
            :nth-child(#{$i + 1}) {
                transition-delay: $transitionDelayFast * $i;
            }
        }
    }

    &__LanguageDesktop {
    }

    &__LanguageMobile {
        margin-right: 20px;
        z-index: 1;
    }

    &__MainNavItem {
        margin: $spacingL 0;
        font-family: $fontBrand;
        font-weight: bold;
        font-size: 2.6rem;
        letter-spacing: 0.04rem;
        line-height: 1.32;
        box-sizing: border-box;
        overflow: hidden;
    }

    &__MainNavItemLink {
        display: inline-flex;
        flex-direction: column;
        -webkit-box-direction: normal;
        position: relative;
        padding: 0 0;
        height: 100%;
        align-items: flex-start;
        margin-top: auto;

        &--Active {
            border-bottom: $colorPink;
        }

        &:focus {
            @include media(ls) {
                height: 100%;
                &:after {
                    left: 0px;
                    height: 4px;
                    right: auto;
                    width: 100%;
                    background: $colorPink;
                    content: '';
                    bottom: 0;
                    position: absolute;
                    -webkit-transition: width 400ms
                        cubic-bezier(0.79, 0.14, 0.16, 0.86);
                    transition: width 400ms cubic-bezier(0.79, 0.14, 0.16, 0.86);
                }
            }
        }

        &--Active {
            @include media(ls) {
                height: 100%;

                & .Header__MainNavItem--Hover {
                    width: 100%;
                    height: 4px;
                    margin-top: auto;
                    display: block;

                    &:after {
                        left: 0px;
                        height: 4px;
                        right: auto;
                        width: 100%;
                        background: $colorPink;
                        content: '';
                        position: absolute;
                        -webkit-transition: width 400ms
                            cubic-bezier(0.79, 0.14, 0.16, 0.86);
                        transition: width 400ms
                            cubic-bezier(0.79, 0.14, 0.16, 0.86);
                    }
                }
            }
        }

        .Header__MainNavItemLabel {
        }

        &:not(&--Active) {
            & .Header__MainNavItem--Hover {
                display: none;

                @include media(ls) {
                    display: block;
                    margin-top: auto;
                    overflow: hidden;
                    position: relative;
                    height: 4px;
                    width: 100%;
                    &:after {
                        background: $colorPink;
                        content: '';
                        height: 4px;
                        position: absolute;
                        right: 0px !important;
                        left: auto !important;
                        width: 0%;
                        -webkit-transition: width 400ms
                            cubic-bezier(0.79, 0.14, 0.16, 0.86);
                        transition: width 400ms
                            cubic-bezier(0.79, 0.14, 0.16, 0.86);
                    }
                }
            }

            &:hover {
                @include media(ls) {
                    & .Header__MainNavItem--Hover {
                        width: 100%;

                        &:after {
                            left: 0px !important;
                            height: 4px;
                            right: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__Toggle {
        background: none;
        height: 27px;
        border: none;
        padding-right: 0;
        margin-left: $spacingS;
        padding-left: 0;

        @include media(xs) {
            margin-left: $spacingL;
        }
    }

    &__ToggleBurger {
        height: 2px;
        margin-bottom: 7px;
        background: $colorWhite;

        &--1 {
            width: 10px;
            margin-left: 14px;
        }

        &--2 {
            width: 18px;
            margin-left: 6px;
        }

        &--3 {
            width: 24px;
            margin-bottom: 0;
        }
    }

    &__MobileHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 88px;
        font-family: $fontBrand;
        font-weight: normal;
        position: relative;

        @include media(xl) {
            width: 1440px;
            margin: 0 auto;
        }

        &--Close {
            cursor: pointer;
        }
    }

    &__MobileHeaderHeadline {
        width: calc(100% - 160px);
        font-size: 1.4rem;
        letter-spacing: 0.26rem;
        text-transform: uppercase;
    }

    &__DropDown {
        transform: translateY(-20px);
        width: 118px;
        font-size: 1.7rem;
        letter-spacing: 0.02rem;
    }

    &__LabeledIcon {
        position: relative;
        margin: 0 $spacingS;

        @include media(xs) {
            margin: 0 $spacingL;
        }
    }

    &__SavedCounter {
        background: $colorPink;
        position: absolute;
        top: -6px;
        right: -2px;
        display: flex;
        justify-content: center;
        align-items: center;

        height: 1.7rem;
        width: 1.7rem;
        border-radius: 50%;
        font-size: 1rem !important;
        line-height: 0.1;
        margin-top: 0;
        display: inherit !important;

        &:empty {
            display: none;
        }
    }
}
