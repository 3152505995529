@import 'styles/includes.scss';

.Picture {
    position: relative;
    max-width: 100%;
    flex: 0 0 auto;
    max-height: 100%;

    &--Shadow {
        left: 8px;
        z-index: 0;

        @include media(m) {
            left: 0;
        }
    }

    &--Small {
        width: 6.4rem;
    }

    &--Medium {
        width: 8rem;
    }

    &--Large {
        width: 100%;
    }

    &__Picture {
        height: 0;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;

        &--Contain {
            background-size: contain;
        }

        &--Small {
            padding-top: calc(64 / 64 * 100%);
        }

        &--Medium {
            padding-top: calc(80 / 80 * 100%);
        }

        &--Large {
            padding-top: calc(226 / 343 * 100%);

            @include media(m) {
                padding-top: calc(408 / 618 * 100%);
            }
        }
    }
}
