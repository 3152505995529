@import 'styles/includes.scss';

.InfoBox {
    font-family: $fontBrand;
    color: $colorBlack800;
    padding: 2.4rem;
    background-color: $colorGray40;
    width: 100%;

    @include media(l) {
        padding: 3.2rem;
    }

    &:not(:first-of-type) {
        @include media(l) {
            margin-left: $spacingXl;
        }
    }

    &:not(:last-of-type) {
        margin-bottom: 10px;

        @include media(l) {
            margin-bottom: 20px;
        }
    }

    &:last-of-type {
        margin-bottom: 16px;
    }

    @include media(l) {
        padding: 3.2rem;
    }

    &__Title {
        @extend %h4;
        margin-bottom: $spacingL;
        color: $colorBlack;
    }

    &__AddressWrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2.4rem;

        .InfoBox__AddressIconWrapper {
            align-self: start;
            margin-top: 3px;
        }
    }

    &__Address {
        font-size: 1.6rem;
        line-height: 1.65;
        color: $colorBlack800;
        display: inline-block;

        p:first-of-type:after {
            content: ',';
        }
    }

    &__Text {
        font-size: 1.6rem;
        line-height: 1.65;
        margin-bottom: 2.3rem;

        a {
            font-size: 1.6rem;
            color: $colorPink700;
            font-size: 1.6rem;
            line-height: 1.65;
            word-break: break-word;
        }

        a[href*="//"]:not([href*="visitstockholm.com"]) {
            position: relative;
            margin-right: 4rem;
            text-decoration: none;

            background-color: $colorGray40;

            &::before,
            &::after {
                content: '';
                backface-visibility: hidden;
                width: 100%;
                height: 0.2rem;
                position: absolute;
                background-color: $colorPink;
                bottom: -4px;
                left: 0px;
                transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
                -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
            }

            &::before {
                transform: scaleX(1) skewX(0deg);
                transform-origin: left center;
                transition-delay: $transitionDelayFast;
            }

            &::after {
                transform: scaleX(0) skewX(0deg);
                transform-origin: right center;
                transition-delay: 0s;
            }

            &:hover {
                &:before {
                    transform: scaleX(0) skewX(45deg);
                    transition-delay: 0s;
                    transform-origin: right center;
                }

                &:after {
                    transform: scaleX(1) skewX(45deg);
                    transition-delay: $transitionDelayFast;
                    transform-origin: left center;
                }
            }

            span::after {
                content: url(#{$assetPath}img/icon-external-link.svg);
                width: 23px;
                height: 24px;
                position: absolute;
                top: 1px;
                right: -30px;
            }
        }

        h4 {
            @extend %h4;
            margin-bottom: 1.2rem;
        }
    }

    &__LinkWrapper,
    &__TelephoneWrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2.4rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__Link {
        color: $colorPink700;
        font-size: 1.6rem;
        line-height: 1.65;
        word-break: break-word;

        &:hover {
            color: $colorPink600;
        }
    }

    &__IconMargin {
        display: inline-flex;
        margin-right: 1.6rem;
    }
}
