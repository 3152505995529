@import 'styles/includes.scss';
@import 'styles/variables';

.Button {
    display: inline-block;
    overflow: visible;
    will-change: transform;

    &__IconContainer {
        margin-left: 10px;
        align-self: center;
        display: flex;
        position: relative;

        .Button__Content--Reverse & {
            margin-left: 0;
            margin-right: 10px;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Container {
        font-family: $fontBrand;
        font-size: 1.4rem;
        padding: 1.15rem 2.45rem;
        border-radius: 50px;
        display: flex;
        letter-spacing: 0.02rem;
        flex-direction: row;
        align-self: center;
        align-items: center;
        text-decoration: none;
        position: relative;

        @include media(l) {
            letter-spacing: 0.03rem;
            font-size: 1.8rem;
            padding: 1.6rem 3.2rem;
            border-radius: 50px;
        }
    }

    .Button--Primary.Button--NoIcon,
    .Button--Secondary.Button--NoIcon,
    .Button--SecondaryInvert.Button--NoIcon {
        font-size: 1.8rem;
        padding: 1.5rem 3.2rem;
    }

    .Button--Primary.Button--Cookiebar {
        font-size: 1.4rem;
        padding: 1.2rem 2.4rem;

        @include media(m) {
            font-size: 1.8rem;
            padding: 1.5rem 3.2rem;
        }
    }
    &__Content {
        display: flex;
        flex-direction: row;
        z-index: 2;
        line-height: normal;
        white-space: nowrap;
        letter-spacing: 0.02rem;

        @include media(l) {
            letter-spacing: 0.03rem;
        }

        &--Reverse {
            flex-direction: row-reverse;
        }

        &--TertiaryHover {
            position: relative;

            &::before,
            &::after {
                content: '';
                backface-visibility: hidden;
                width: 100%;
                height: 0.2rem;
                position: absolute;
                background-color: inherit;
                bottom: -4px;
                left: 0px;
                transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
                    -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
            }

            &::before {
                transform: scaleX(1) skewX(0deg);
                transform-origin: left center;
                transition-delay: $transitionDelayFast;
            }
            &::after {
                transform: scaleX(0) skewX(0deg);
                transform-origin: right center;
                transition-delay: 0s;
            }

            .Button:hover &:before {
                transform: scaleX(0) skewX(45deg);
                transition-delay: 0s;
                transform-origin: right center;
            }

            .Button:hover &:after {
                transform: scaleX(1) skewX(45deg);
                transition-delay: $transitionDelayFast;
                transform-origin: left center;
            }
        }
        &--Pink.Button__Content--TertiaryHover::before,
        &--Pink.Button__Content--TertiaryHover::after {
            background-color: $colorPink;
        }

        &--White.Button__Content--TertiaryHover::before,
        &--White.Button__Content--TertiaryHover::after {
            background-color: $colorWhiteBackground;
        }

        &--Black.Button__Content--TertiaryHover::before,
        &--Black.Button__Content--TertiaryHover::after {
            background-color: $colorBlack800;
        }
    }

    &--Tertiary {
        background-color: transparent;
        font-size: 1.6rem;
        letter-spacing: 0.03rem;
        border: none;
        border-radius: 0;
        line-height: normal;
        padding: 2px 0 4px 0;

        &[disabled] {
            color: $colorGray300;

            svg {
                color: $colorGray300;
            }

            .Button__Content--TertiaryHover::before,
            .Button__Content--TertiaryHover::after {
                transition: none;
                background-color: $colorGray300;
            }
        }
    }

    &--Pink {
        color: $colorPink;
    }

    &--White {
        color: $colorWhiteBackground;
    }

    &--Black {
        color: $colorBlack800;
    }

    &--Primary,
    &--Secondary,
    &--SecondaryInvert {
        transition: transform $transition;
        overflow: hidden;
        transform: translateY(0px);
        &:hover {
            transform: translateY(-4px);
        }
    }

    &--Primary {
        background-color: $colorPink;
        color: $colorWhite;
        border: none;
        display: inline-block;

        .Icon svg {
            color: $colorWhite;
        }

        &[disabled] {
            background-color: $colorGray200;
            color: $colorGray500;
            svg {
                color: $colorGray500;
            }
            .Button--PressedIcon {
                display: none;
            }
        }

        .Button--PressedIcon {
            background-color: #c80058;
        }
    }

    &--Secondary {
        background-color: $colorWhiteBackground;
        border: 1px solid $colorBlack800;
        color: $colorBlack800;

        &.Button__Container--Pressed {
            border: none;
            background-color: $colorBlack800;
            color: $colorWhite;

            svg {
                color: $colorWhite;
            }
        }

        @include media(l) {
            border-width: 2px;
        }

        &:hover {
            background-color: $colorBlack800;
            color: $colorWhite;
            svg {
                color: $colorWhite;
            }
        }

        &[disabled] {
            color: $colorGray300;
            border: 1px solid $colorGray300;
            svg {
                color: $colorGray300;
            }
        }

        .Button--PressedIcon {
            background-color: $colorGray700;
        }
    }

    &--SecondaryInvert {
        background-color: $colorBlack800;
        border: 1px solid $colorWhiteBackground;
        color: $colorWhiteBackground;
    }

    &--IsClicked {
        background-color: $colorBlack800;
        color: $colorWhite;
        svg {
            color: $colorWhite;
        }
    }
}
