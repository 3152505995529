@import 'styles/includes.scss';

$transition: 0.4s ease-in-out;

.Card {
    $root: &;
    font-family: $fontBrand;
    position: relative;
    cursor: pointer;
    display: block;
    z-index: 1;
    min-width: 25.3rem;
    max-width: 100%;
    width: 100%;

    @include media(l) {
        min-width: 29.7rem;
    }

    @include media(xl) {
        min-width: 34.2rem;
    }

    &--Large {
        @include media(ml) {
            min-width: calc((#{map-get($maxwidths, ml)} - 48px) / 3);
            max-width: calc((#{map-get($maxwidths, ml)} - 48px) / 3);
        }

        @include media(l) {
            min-width: calc((#{map-get($maxwidths, l)} - 48px) / 3);
            max-width: calc((#{map-get($maxwidths, l)} - 48px) / 3);
        }

        @include media(xl) {
            min-width: calc((#{map-get($maxwidths, xl)} - 48px) / 3);
            max-width: calc((#{map-get($maxwidths, xl)} - 48px) / 3);
        }
    }

    &--Xlarge {
        width: 100%;
        margin-right: 0;
        margin-bottom: 3.2rem;

        @include media(m) {
            width: calc(50% - (#{$spacingXl} / 2));
            margin-top: 0;
            margin-bottom: 0;
        }

        ~ .Card--Xlarge {
            padding-right: 0;
        }
    }

    &--Full {
        width: 100%;

        @include media(l) {
            width: calc(100% - #{$spacingXl});
        }
    }

    &--Featured {
        margin-top: 0;
        margin-bottom: 3.2rem;
    }

    &__Picture {
        display: flex;
        justify-content: center;
        height: 100%;
        transition: transform 0.5s;
        backface-visibility: hidden;
        overflow: hidden;

        #{$root}:hover & {
            transform: scale3d(1.08, 1.08, 1);

            // Disable the above transform in safari since it causes flickering when swiping
            @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance: none) and
                    (stroke-color: transparent) {
                    transform: none;
                }
            }
        }
    }

    img {
        object-fit: cover;
        width: 100%;
    }

    &__Shadow {
        background-image: linear-gradient(
            to bottom,
            rgba(24, 24, 24, 0),
            rgba(24, 24, 24, 1)
        );
        transform: translateY(80px);
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        transition: transform $transition;

        @include media(m) {
            background-image: linear-gradient(
                to bottom,
                rgba(24, 24, 24, 0),
                rgba(24, 24, 24, 0.85)
            );
        }

        #{$root}--WithByline:hover & {
            transform: translateY(0);
        }
    }

    &__Information {
        position: absolute;
        bottom: 0;
        left: 16px;
        padding-right: 16px;
        color: $colorWhite;
    }

    &__InformationHeading {
        display: block;
        font-size: 1.7rem;
        line-height: 2.4rem;
        letter-spacing: 0.02rem;
        font-weight: bold;
        margin-bottom: 1rem;

        @include media(l) {
            font-size: 1.9rem;
            line-height: 3.2rem;
            letter-spacing: 0.04rem;
            margin-bottom: 0.8rem;
        }

        &--AddMargin {
            margin-bottom: 3rem !important;
        }
    }

    &__InformationCategory {
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: 0.26rem;
        margin-bottom: 20px;
        color: $colorGray100;
    }

    &__InformationQuote {
        margin-top: 4px;
        margin-bottom: 20px;
        font-size: 1.6rem;
        line-height: 2.64rem;
        color: $colorGray100;
    }

    &--Locals {
        #{$root}--HasQuote {
            width: 90%;

            @include media(m) {
                transition: transform $transition;
                bottom: 2.7rem;
            }

            #{$root}__InformationHeading {
                margin-bottom: 0.4rem;
            }

            #{$root}__InformationQuote {
                width: 100%;

                @include media(m) {
                    position: absolute;
                    opacity: 0;
                    transition: opacity $transition;
                }
            }
        }

        @include media(m) {
            &:hover {
                #{$root}__InformationQuote {
                    opacity: 1;
                }

                #{$root}--HasQuote {
                    transform: translateY(-40px);
                }
            }
        }
    }

    &--WithByline {
        @include media(m) {
            #{$root}__Information {
                transition: transform $transition;
                transform: translateY(80px);
            }
            &:hover {
                #{$root}__Information {
                    transform: translateY(0px);
                }
            }
        }
    }

    &__Byline {
        height: 80px;
        align-items: flex-start;

        :global {
            .Byline__Info {
                justify-content: flex-start !important;
            }
        }
    }

    &__Wrapper {
        overflow: hidden;
        height: 100%;
        position: relative;

        #{$root}--Small & {
            height: 31.2rem;
        }

        #{$root}--Medium & {
            height: 31.2rem;
            @include media(l) {
                height: 35.9rem;
            }
        }

        #{$root}--Large & {
            height: 3.12rem;
        }

        #{$root}--Xlarge & {
            height: 288px;

            @include media(l) {
                height: 408px;
            }
        }
    }
}

.Card--White {
    position: relative;

    .Card__Information-wrapper {
        color: $colorBlack800;
        margin-top: 1.3rem;
    }

    .Card__InformationHeading {
        color: $colorBlack;
    }

    .Card__InformationCategory,
    .Card__InformationQuote {
        color: $colorBlack800;
    }

    .Card__Shadow {
        opacity: 0;
    }

    &.Card--WithByline {
        .Card__Shadow {
            opacity: 1;

            @include media(m) {
                opacity: 0;
                transition: opacity 0.3s;
            }
        }
    }

    &.Card--WithByline:hover {
        .Card__Shadow {
            opacity: 1;
        }
    }
}
