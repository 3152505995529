@import 'styles/includes.scss';

.CollaborationText {
    border-top: 1px solid $colorGray300;
    border-bottom: 1px solid $colorGray300;
    padding: $spacingL 0;

    p {
        @extend %body-copy;
    }

    a {
        @extend %links;
    }
}
