@import 'styles/includes.scss';

$transition: 0.4s ease-in-out;

.CardGuide {
    $root: &;
    font-family: $fontBrand;
    position: relative;
    cursor: pointer;
    display: block;
    z-index: 1;
    height: auto;
    width: 100%;

    &--TwoColumns {
        flex-basis: 100%;
        margin-bottom: 1.9rem;

        @include media(m) {
            flex-basis: calc(50% - 2.4rem);
            min-width: calc(50% - 2.4rem);
            max-width: calc(50% - 2.4rem);
            margin-right: 2.4rem;
        }
    }

    &--ThreeColumns {
        flex-basis: 100%;
        margin-bottom: 1.9rem;

        @include media(m) {
            flex-basis: calc(50% - 2.4rem);
            min-width: calc(50% - 2.4rem);
            max-width: calc(50% - 2.4rem);
            margin-right: 2.4rem;
        }

        @include media(l) {
            flex-basis: calc((100% / 3) - 2.4rem);
            min-width: calc((100% / 3) - 2.4rem);
            max-width: calc((100% / 3) - 2.4rem);
        }
    }

    &--FullWidth {
        flex-basis: 100%;
        height: 46rem;
        min-width: 100% !important;
        max-width: 100% !important;

        #{$root}__InformationHeading {
            font-size: 1.7rem;

            @include media(ml) {
                font-size: 2.4rem;
            }
        }
    }

    &--WithByline {
        @include media(m) {
            #{$root}__Information {
                transition: transform $transition;
                transform: translateY(80px);
            }
        }
    }

    &__Link {
        font-family: $fontBrand;
        position: relative;
        cursor: pointer;
        display: block;
        overflow: hidden;
        z-index: 1;
        min-width: 100%;
        max-height: 100%;
        height: 280px;

        @include media(ml) {
            height: 332px;
        }

        @include media(l) {
            height: 312px;
        }

        &--WithByline {
            &:hover {
                #{$root}__Information {
                    @include media(m) {
                        transform: translateY(0px);
                    }
                }

                #{$root}__Shadow {
                    @include media(m) {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__Picture {
        display: flex;
        justify-content: center;
        min-width: 100%;
        height: 100%;
        transition: transform $transition;
        overflow: hidden;

        & img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }

        & .NoImage {
            padding-bottom: 90%;
        }

    }

    &__Shadow {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(180deg,rgba(24,24,24,0),rgba(24,24,24,.85));
        transition: opacity 0.2s ease-in-out;

        @include media(m) {
            transform: translateY(80px);
        }

        #{$root}--MyList &, #{$root}--LocalsPage & {
            opacity: 0;
        }

        &--NoImage {
            background-image: linear-gradient(
                    to bottom,
                    transparent 0%,
                    $colorBlack800 100%
            );
        }
    }

    &__Information {
        font-family: $fontBrand;
        position: absolute;
        bottom: 16px;
        left: 16px;
        padding-right: 16px;
        color: $colorWhite;
        transform: translateY(20px);

        &--Under {
            margin-top: 1.3rem;
            font-family: $fontBrand;
        }
    }

    &__InformationHeading {
        display: block;
        font-size: 1.7rem;
        line-height: 1.41;
        font-weight: bold;
        letter-spacing: 0.5px;

        &--AddMargin {
            margin-bottom: 2rem;
        }

        @include media(l) {
            font-size: 1.9rem;
            line-height: 1.68;
            letter-spacing: 0.04rem;
        }
    }

    &__InformationCategory {
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: 0.26rem;
        margin-top: 0.8rem;
        margin-bottom: 2rem;

        &--Under {
            margin-bottom: 1.5rem;
        }
    }

    &__RemoveButton {
        border-radius: 50%;
        width: 2.8rem;
        height: 2.8rem;
        background: $colorGray10;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        right: 1rem;
        top: 1rem;

        &:hover {
            background-color: $colorGray200;
        }

        & svg {
            display: flex;
        }
    }
}
