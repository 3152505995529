@import 'styles/includes.scss';

.Cookiebar {
    opacity: 1;
    background-color: $colorBlue50;
    transition: all $transition;
    font-family: $fontBrand;
    color: $colorBlack800;

    &__Container {
        @extend %container;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }

    a {
        color: $colorBlack800;
    }

    &__Text {
        font-size: 1.6rem;
        line-height: 1.65;
        margin-right: 1.5rem;

        @include media(m) {
            margin-right: 4.2rem;
        }
    }

    &--Hidden {
        display: none;
    }

    &__Button {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
