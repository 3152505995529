@import 'styles/variables.scss';

.DropdownLinks {
    position: relative;

    &__Button {
        display: flex;
        align-items: center;
        @extend %nav-subtitles;
        color: $colorWhite;
        background-color: $colorBlack800;
        padding: 20px;
        border: 1px solid transparent;
        border-bottom: none;

        &--Open {
            border: 1px solid $colorBlack750;
            border-bottom: none;
        }

        &:focus {
            outline: none !important;
        }

    }

    &__Icon {
        transform: rotate(90deg);
        margin-left: 10px;
        transition: transform .2s ease-in-out;

        &--Open {
            transform: rotate(-90deg);
        }
    }

    &__Menu {
        display: none;
        position: absolute;
        width: 100%;
        flex-direction: column;
        background-color: $colorBlack800;
        border-radius: 0 0 12px 12px;
        border: 1px solid $colorBlack750;
        border-top: none;

        &--Open {
            display: flex;
        }
    }

    &__MenuItem {
        @extend %nav-subtitles;
        color: $colorWhite;
        text-decoration: none;
        padding: 20px;
        &:hover {
            text-decoration: underline;
        }
    }

}
