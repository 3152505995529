@import 'styles/includes.scss';

.Contact {
    display: flex;
    flex-direction: column;

    @include media(m) {
        flex-direction: row;
        margin-right: 40px;
    }

    &:not(:last-of-type) {
        margin-bottom: 24px;
    }

    &__Image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 16px;

        @include media(m) {
            margin-right: 40px;
        }
    }

    &__ImageComponent {
        height: 100%;
        width: 100%;
    }

    &__Title {
        @extend %h5;
    }

    &__Text {
        @extend %body-copy;

        &--JobTitle {
            margin-bottom: 20px;
        }
    }

    &__Link {
        @extend %links;
    }
}
