@import 'styles/includes.scss';

.VideoModule {
    $root: &;
    padding-bottom: 4rem;

    @include media(m) {
        padding-bottom: 6rem;
    }

    @include media(m) {
        padding-bottom: 9rem;
    }

    &__Container {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: calc((16 / 16) * 100vw);

        @include media(m) {
            max-height: 75vh;
            height: calc((9 / 16) * 100vw);
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 17.6rem;
            bottom: 0rem;
            left: 0;
            z-index: 1;
            background: linear-gradient(
                to bottom,
                rgba(24, 24, 24, 0),
                rgba(24, 24, 24, 0.13),
                rgba(24, 24, 24, 0.26),
                rgba(24, 24, 24, 0.58),
                $colorBlack800
            );
        }
    }

    &__Video {
        position: absolute;
        padding-bottom: calc(100% * 1.4);
        width: calc(177% * 1.4);
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);

        @include media(xs) {
            padding-bottom: calc(100% * 1.2);
            width: calc(177% * 1.2);
        }

        @include media(m) {
            display: flex;
            align-items: center;
            height: 100%;
            position: relative;
            width: unset;
            left: unset;
            transform: unset;
            padding-bottom: unset;
            top: unset;

            & div {
                flex: 1;
            }
        }
    }

    &__Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;

        opacity: 1;
        transition: opacity 0.2s;
        transition-delay: $transitionDelayFast;

        &--VideoLoaded {
            opacity: 0;
        }
    }

    &__TextWrapper {
        @extend %container;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        margin-top: -2.9rem;
        width: 100%;
        transform: translateY(40px);

        @include media(m) {
            flex-direction: row;
            margin-top: -3rem;
        }

        @include media(l) {
            margin-top: 0;
        }
    }

    &__InnerWrapper {
        flex: 1 1 70%;

        @include media(m) {
            padding-right: $spacingXl;
        }
    }

    &__Title {
        @extend %h1;
        font-size: 4rem;
        margin-bottom: $spacingL;
        color: $colorWhite;

        transition: opacity 0.3s ease-out, transform 0.3s ease-out;
        transition-delay: $transitionDelayFast;
    }

    &__Preamble {
        font-family: $fontBrand;
        color: $colorGray100;
        max-width: 61.3rem;
        width: 100%;
        line-height: 1.65;
        font-size: 2rem;
        margin-bottom: 16px;

        transition: opacity 0.3s ease-out, transform 0.3s ease-out;
        transition-delay: $transitionDelayMedium;

        @include media(m) {
            font-size: 2.2rem;
            line-height: 1.6;
            letter-spacing: normal;
            margin-bottom: 0;
        }
    }

    &__ButtonsWrapper {
        @include media(m) {
            flex: 0 1 30%;
            padding-top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @include media(ml) {
            align-items: flex-end;
        }

        @include media(l) {
            flex: 0 1 calc(305 / 1260 * 100%);
            flex-flow: row wrap;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    &__VideoButton {
        font-family: $fontBrand;
        font-size: 1.6rem;
        letter-spacing: 0rem;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: $colorWhite;
        padding: 0.5rem 0.5rem 0.5rem 0;

        @include media(m) {
            &:not(:last-child) {
                margin-bottom: $spacingL;
            }
        }

        @include media(l) {
            margin-bottom: 0;
            margin-left: 20px;

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }

    &__IconBackground {
        width: 2.4rem;
        height: 2.4rem;
        background-color: $colorWhite;
        border-radius: 50%;
        position: relative;
        margin-right: $spacingS;
    }

    &__IconWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__ButtonText {
        position: relative;

        &::after {
            content: '';
            background-color: $colorWhite;
            height: 1px;
            position: absolute;
            bottom: -0.2rem;
            left: 0;
            width: 100%;
        }
    }

    &__Modal {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: opacity $transition;

        &--Show {
            visibility: visible;
            opacity: 1;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 10;
        }
    }

    &__ModalWrapper {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    &__ModalContent {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 36.6rem;

        @include media(m) {
            max-width: 67.6rem;
        }
    }

    &__ModalPlayer {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
    }

    &__CloseModal {
        position: absolute;
        top: -7rem;
        right: 0rem;
        background: none;
        border: none;
        width: 4rem;
        height: 4rem;
        background-color: $colorWhite;
        border-radius: 50%;

        @include media(ml) {
            right: -6rem;
        }
    }

    &__CloseIconWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
