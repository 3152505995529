@import 'styles/includes.scss';
@import 'styles/variables.scss';

.EventList {
    display: flex;
    flex-direction: column;

    &__DateHeading {
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: 2.6px;
        font-family: $fontBrand;
        display: block;
        padding: $spacingL 0;
        margin-top: 1rem;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .CardEvent--Horizontal:first-of-type {
        padding-top: 0;
    }

    .CardEvent__MetaRow {
        align-items: flex-start;
    }

    &__animation-enter {
        opacity: 0.01;
    }

    &__animation-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-in;
    }

    &__animation-leave {
        opacity: 1;
    }

    &__animation-exit {
        opacity: 1;
    }

    &__animation-exit-active {
        opacity: 0.01;
        transition: opacity 400ms ease-in;
    }
}
